<template>
  <section class="section flex features">
    <div class="section-header f fs">
      <h2>{{ $t('homepage.features.title') }}</h2>
      <!--      <client-only>-->
      <svg class="wh14 ml10">
        <use xlink:href="/icons/sprite.svg#i_blink" />
      </svg>
      <!--      </client-only>-->
    </div>
    <div class="section-body">
      <nav class="features-nav">
        <ul class="list">
          <!--          <li>-->
          <!--            <NuxtLink-->
          <!--                :to="localePath(`/item/awp-asiimov`)"-->
          <!--                class="card"-->
          <!--            >-->
          <!--              &lt;!&ndash;              <client-only>&ndash;&gt;-->
          <!--              <svg class="wh22">-->
          <!--                <use xlink:href="/icons/sprite.svg#i_skins" />-->
          <!--              </svg>-->
          <!--              &lt;!&ndash;              </client-only>&ndash;&gt;-->
          <!--              <div class="f">-->
          <!--                <p>{{ $t('homepage.features.Item price analytics') }}</p>-->
          <!--                <span class="i-arrow wh20 f14 rot_l"></span>-->
          <!--              </div>-->
          <!--            </NuxtLink>-->
          <!--          </li>-->
          <li>
            <NuxtLink :to="{path: localePath('/comparison'), query: {}}" class="card">
              <!--              <client-only>-->
              <svg class="wh24">
                <use xlink:href="/icons/sprite.svg#i_compare" />
              </svg>
              <!--              </client-only>-->
              <div class="f">
                <p>{{ $t('homepage.features.Items price comparison') }}</p>
                <span class="i-arrow wh20 f14 rot_l"></span>
              </div>
            </NuxtLink>
          </li>
          <li>
            <NuxtLink :to="localePath('/search')" class="card">
              <!--              <client-only>-->
              <i class="icon i-search f24 wh24" />
              <!--              </client-only>-->
              <div class="f">
                <p>{{ $t('homepage.features.Advanced search') }}</p>
                <span class="i-arrow wh20 f14 rot_l"></span>
              </div>
            </NuxtLink>
          </li>
          <li>
            <NuxtLink :to="localePath('/blog')" class="card">
              <!--              <client-only>-->

              <svg height="24" width="24">
                <use xlink:href="/icons/sprite.svg#i-blog"></use>
              </svg>
              <!--              </client-only>-->
              <div class="f">
                <p>{{ $t('blog') }}</p>
                <span class="i-arrow wh20 f14 rot_l"></span>
              </div>
            </NuxtLink>
          </li>
          <li>
            <NuxtLink
                :to="localePath(`/colors`)"
                class="card"
            >
              <!--              <client-only>-->
              <svg class="wh22">
                <use xlink:href="/icons/sprite.svg#palette" />
              </svg>
              <!--              </client-only>-->
              <div class="f">
                <p>{{ $t('сolor_groups') }}</p>
                <span class="i-arrow wh20 f14 rot_l"></span>
              </div>
            </NuxtLink>
          </li>
          <!--          <li>-->
          <!--            <NuxtLink :to="localePath('/inventory')" class="card">-->
          <!--              &lt;!&ndash;              <client-only>&ndash;&gt;-->
          <!--              <i class="icon i-analitycs f24 wh24" />-->
          <!--              &lt;!&ndash;              </client-only>&ndash;&gt;-->
          <!--              <div class="f">-->
          <!--                &lt;!&ndash;                <p>{{ $t('homepage.features.Inventory') }}</p>&ndash;&gt;-->
          <!--                <p>Inventory</p>-->
          <!--                <span class="i-arrow wh20 f14 rot_l"></span>-->
          <!--              </div>-->
          <!--            </NuxtLink>-->
          <!--          </li>-->
        </ul>
      </nav>
    </div>
  </section>
</template>

<script lang="ts" setup>
import {useLocalePath} from "#i18n";

const localePath = useLocalePath()
</script>

<style lang="scss" scoped>
@use '/assets/scss/variables.scss' as *;
@use 'sass:color';

.features {
  .subtitle {
    max-width: 40rem;
    margin-top: 1.6rem;
    margin-bottom: 0.8rem;
  }

  &-nav {
    padding-bottom: 2rem;

    ul {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      grid-gap: 1rem;
    }

    li {
      a {
        display: block;
        background: $dark;
        border-radius: 0.8rem;
        padding: 2.4rem;
        font-size: 2.1rem;
        line-height: 2.8rem;
        font-weight: 700;
        height: 100%;
        //font-family: $fontTitle;
        i, svg {
          color: $green;
        }

        &:hover {
          background: color.adjust($dark, $lightness: 3%);
        }
      }

      p {
        max-width: 17rem;
        margin-top: 1.5rem;
      }
    }
  }
}
</style>
